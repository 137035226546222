var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { id: _vm.node.id } },
    [
      _vm.pulsable(_vm.node)
        ? _c("rect", {
            staticClass: "diagram--editor__pulse",
            attrs: {
              fill: _vm.pulseColor(_vm.node),
              stroke: _vm.pulseColor(_vm.node),
              x: _vm.node.x,
              y: _vm.node.y,
              rx: "3",
              ry: "3",
              width: _vm.node.width,
              height: _vm.node.height
            }
          })
        : _vm._e(),
      _c("rect", {
        attrs: {
          fill: _vm.color(_vm.node),
          stroke: "#000000",
          "stroke-width": _vm.selected ? 1 : 0,
          x: _vm.node.x,
          y: _vm.node.y,
          rx: "3",
          ry: "3",
          width: _vm.node.width,
          height: _vm.node.height
        }
      }),
      _c("DiagramNodeTitle", {
        attrs: {
          x: _vm.node.x + 2,
          y: _vm.node.y + 2,
          width: _vm.node.size.width - 4,
          title: _vm.node.title,
          dragging: _vm.dragging,
          deletable: _vm.nodeDeletable
        },
        on: { delete: _vm.deleteNode },
        nativeOn: {
          mousedown: function($event) {
            $event.stopPropagation()
            return _vm.onDragStart($event)
          }
        }
      }),
      _c("rect", {
        staticClass: "node-light-background",
        attrs: {
          fill: "#ffffff",
          x: _vm.node.x + 2,
          y: _vm.node.y + 20,
          rx: "3",
          ry: "3",
          width: _vm.node.width - 4,
          height: _vm.node.height - 22
        }
      }),
      _c(
        "foreignObject",
        {
          attrs: {
            x: _vm.node.x + 2,
            y: _vm.node.y + 20,
            width: _vm.slotWidth,
            height: _vm.slotHeight
          }
        },
        [
          _c(
            "body",
            {
              staticClass: "diagram-editor__node-body",
              attrs: { xmlns: "http://www.w3.org/1999/xhtml" }
            },
            [
              _vm._t("default", null, {
                width: _vm.slotWidth,
                height: _vm.slotHeight
              })
            ],
            2
          )
        ]
      ),
      _c(
        "svg",
        {
          attrs: {
            x: _vm.node.x - 4,
            y: _vm.node.y + _vm.node.height - _vm.portsHeight - 2,
            width: _vm.node.width + 8,
            height: _vm.portsHeight
          }
        },
        [
          _vm._l(_vm.portsIn, function(ref, portIndex) {
            var port = ref[0]
            var portTitle = ref[1]
            return _c("DiagramPort", {
              key: _vm.node.id + "_in_" + port,
              attrs: {
                id: _vm.node.id + "_in_" + port,
                title: portTitle,
                width:
                  portIndex + 1 > _vm.portsOut.length
                    ? _vm.node.width
                    : _vm.node.width / 2,
                x: 0,
                y: portIndex * 18,
                disabled: _vm.portsInDisabled[port],
                available: _vm.portsInAvailable[port],
                hovered: _vm.isHoveredPort("in", port),
                align: "start"
              },
              on: {
                mousedown: function($event) {
                  return _vm.mouseDownPort("in", port, $event)
                },
                mouseenter: function($event) {
                  return _vm.mouseEnterPort("in", port, $event)
                },
                mouseleave: function($event) {
                  return _vm.mouseLeavePort("in", port, $event)
                }
              }
            })
          }),
          _vm._l(_vm.portsOut, function(ref, portIndex) {
            var port = ref[0]
            var portTitle = ref[1]
            return _c("DiagramPort", {
              key: _vm.node.id + "_out_" + port,
              attrs: {
                id: _vm.node.id + "_out_" + port,
                title: portTitle,
                width: _vm.node.width + 8,
                x: 0,
                y: portIndex * 18,
                disabled: _vm.portsOutDisabled[port],
                available: _vm.portsOutAvailable[port],
                hovered: _vm.isHoveredPort("out", port),
                align: "end"
              },
              on: {
                mousedown: function($event) {
                  return _vm.mouseDownPort("out", port, $event)
                },
                mouseenter: function($event) {
                  return _vm.mouseEnterPort("out", port, $event)
                },
                mouseleave: function($event) {
                  return _vm.mouseLeavePort("out", port, $event)
                }
              }
            })
          })
        ],
        2
      ),
      _c(
        "svg",
        {
          attrs: {
            width: "10",
            height: "10",
            x: _vm.node.x + _vm.node.width - 10,
            y: _vm.node.y + _vm.node.height - 10
          },
          on: { mousedown: _vm.onResizeStart }
        },
        [
          _c("path", {
            staticStyle: { cursor: "nwse-resize" },
            attrs: { d: "M 0,10 10,0 v 10 z", fill: "#000" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }