var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      style: { cursor: _vm.dragging ? "grabbing" : "grab" },
      attrs: { x: _vm.x, y: _vm.y, width: _vm.width, height: "16" },
      on: {
        mouseenter: function($event) {
          _vm.hover = true
        },
        mouseleave: function($event) {
          _vm.hover = false
        }
      }
    },
    [
      _c("rect", {
        attrs: {
          fill: "#000",
          "fill-opacity": _vm.titleFillOpacity,
          x: "0",
          y: "0",
          rx: "3",
          ry: "3",
          width: _vm.width,
          height: "16"
        }
      }),
      _c(
        "svg",
        {
          attrs: {
            x: "0",
            y: "0",
            width: _vm.deletable ? _vm.width - 17 : _vm.width,
            height: "16"
          }
        },
        [
          _c("title", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "text",
            {
              attrs: {
                x: "2",
                y: "13",
                "font-size": "15",
                "font-weight": "normal",
                fill: "#fff"
              }
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        ]
      ),
      _vm.deletable
        ? _c(
            "svg",
            {
              staticClass: "diagram-editor__delete",
              attrs: { x: _vm.width - 15, y: "1", width: "14", height: "14" },
              on: {
                click: function($event) {
                  return _vm.$emit("delete")
                }
              }
            },
            [
              _c("rect", {
                attrs: {
                  x: "0",
                  y: "0",
                  width: "14",
                  height: "14",
                  rx: "2",
                  ry: "2"
                }
              }),
              _c("line", { attrs: { x1: 1, y1: 1, x2: 13, y2: 13 } }),
              _c("line", { attrs: { x1: 13, y1: 1, x2: 1, y2: 13 } })
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }