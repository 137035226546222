function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Vue from 'vue';
import Node from '../models/Node';
import Link from '../models/Link';
import Coordinates from '../models/Coordinates';
import { snapToGrip } from '../helpers';
import Size from '../models/Size';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from "lodash/isEqual";
export default (function () {
  return {
    data: function data() {
      return {
        nodes: {},
        links: {},
        draggedNode: null,
        resizedNode: null,
        selectedNode: null,
        selectedLink: null,
        initialCursorOffset: {
          x: 0,
          y: 0
        },
        activePort: null,
        hoveredPort: null,
        nodeCoordinatesBeforeDrag: {
          x: 0,
          y: 0
        },
        nodeSizeBeforeResize: {
          width: 0,
          height: 0
        },
        currentCursorPos: null
      };
    },
    computed: {
      newLink: function newLink() {
        if (!this.activePort || this.currentCursorPos === null) return null;
        var _this$activePort = this.activePort,
            id = _this$activePort.id,
            type = _this$activePort.type,
            port = _this$activePort.port;
        var startCoordinates = this.nodes[id].getPortCoordinates(type, port);
        return {
          x1: startCoordinates.x,
          y1: startCoordinates.y,
          x2: this.currentCursorPos.x,
          y2: this.currentCursorPos.y
        };
      }
    },
    watch: {
      selectedNode: function selectedNode(id) {
        if (id) {
          this.$emit('select-node', id);
        }
      },
      draggedNode: function draggedNode(id) {
        if (id) {
          var node = this.nodes[id];
          this.nodeCoordinatesBeforeDrag = _objectSpread({}, node.coordinates);
        }

        this.toggleMoveEventListener(id !== null);
      },
      resizedNode: function resizedNode(id) {
        if (id) {
          var node = this.nodes[id];
          this.nodeSizeBeforeResize = _objectSpread({}, node.size);
        }

        this.toggleMoveEventListener(id !== null);
      },
      activePort: function activePort(v) {
        this.toggleMoveEventListener(v !== null);
      }
    },
    methods: {
      setModel: function setModel(model) {
        this.nodes = Object.values(model.nodes || []).reduce(function (carry, node) {
          var nodeModel = new Node(node);
          return _objectSpread(_objectSpread({}, carry), {}, _defineProperty({}, nodeModel.id, nodeModel));
        }, {});
        this.links = Object.values(model.links || []).reduce(function (carry, link) {
          var linkModel = new Link(link);
          return _objectSpread(_objectSpread({}, carry), {}, _defineProperty({}, linkModel.id, linkModel));
        }, {});
      },
      beforePan: function beforePan() {
        return this.pan && !(this.draggedNode || this.resizedNode || this.activePort);
      },
      addNode: function addNode(node) {
        var nodeModel = new Node(node);
        Vue.set(this.nodes, nodeModel.id, nodeModel);
      },
      addLink: function addLink(link) {
        var linkModel = new Link(link);
        Vue.set(this.links, linkModel.id, linkModel);
      },
      deleteNode: function deleteNode(id) {
        var node = this.nodes[id];
        if (this.beforeDeleteNode(node) === false) return;
        Vue.delete(this.nodes, id);

        for (var linkId in this.links) {
          if (this.links.hasOwnProperty(linkId)) {
            var link = this.links[linkId];

            if (link.start_id === id || link.end_id === id) {
              Vue.delete(this.links, linkId);
            }
          }
        }

        this.$emit('deleted-node', id);
      },
      deleteLink: function deleteLink(id) {
        if (this.beforeDeleteLink(this.links[id]) === false) return;
        Vue.delete(this.links, id);
        this.$emit('deleted-link', id);
      },
      selectLink: function selectLink(id) {
        this.selectedLink = id;
      },
      clearSelection: function clearSelection() {
        this.selectedNode = null;
        this.selectedLink = null;
      },
      toggleMoveEventListener: function toggleMoveEventListener(enable) {
        if (enable) {
          document.body.addEventListener('mousemove', this.mouseMove);
          document.body.addEventListener('mouseup', this.mouseUp);
        } else {
          document.body.removeEventListener('mousemove', this.mouseMove);
          document.body.removeEventListener('mouseup', this.mouseUp);
        }
      },
      mouseMove: function mouseMove(pos) {
        if (this.draggedNode || this.resizedNode) {
          this.dragMove({
            gridSnap: this.gridSnap,
            x: pos.x,
            y: pos.y
          });
        } else if (this.activePort) {
          var _this$convertXYtoView = this.convertXYtoViewPort(pos.pageX, pos.pageY),
              x = _this$convertXYtoView.x,
              y = _this$convertXYtoView.y;

          this.currentCursorPos = {
            x: x,
            y: y
          };
        }
      },
      mouseUp: function mouseUp() {
        if (this.draggedNode || this.resizedNode) {
          var id = this.draggedNode || this.resizedNode;
          var node = id ? this.nodes[id] : null;
          var isDragged = !!this.draggedNode;
          var hasChanges = this.draggedNode && !isEqual(_objectSpread({}, node.coordinates), _objectSpread({}, this.nodeCoordinatesBeforeDrag)) || this.resizedNode && !isEqual(_objectSpread({}, node.size), _objectSpread({}, this.nodeSizeBeforeResize));
          this.dragEnd();

          if (hasChanges) {
            this.$emit('updated-node', node);
          } else if (isDragged) {
            this.selectedNode = id;
          }
        } else if (this.activePort) {
          var activePort = _objectSpread({}, this.activePort);

          if (this.hoveredPort) {
            var hoveredPort = _objectSpread({}, this.hoveredPort);

            if (isEqual(activePort, hoveredPort)) {
              this.$emit('click-port', activePort);
            } else {
              if (this.portAvailable(_objectSpread(_objectSpread({}, hoveredPort), {}, {
                activePort: activePort
              }))) {
                var link = new Link({
                  start_id: activePort.id,
                  start_port: activePort.port,
                  end_id: hoveredPort.id,
                  end_port: hoveredPort.port
                });
                Vue.set(this.links, link.id, link);
                this.$emit('created-link', link);
              }
            }
          }

          this.activePort = null;
          this.currentCursorPos = null;
        }
      },
      serialize: function serialize() {
        return {
          nodes: Object.values(this.nodes).map(function (node) {
            return cloneDeep(node);
          }),
          links: Object.values(this.links).map(function (link) {
            return cloneDeep(link);
          })
        };
      },
      dragStart: function dragStart(_ref) {
        var id = _ref.id,
            offset = _ref.offset,
            type = _ref.type;

        if (type === 'drag') {
          this.draggedNode = id;
          this.resizedNode = null;
        } else if (type === 'resize') {
          this.draggedNode = null;
          this.resizedNode = id;
        }

        this.initialCursorOffset = offset;
      },
      dragMove: function dragMove(_ref2) {
        var _this = this;

        var gridSnap = _ref2.gridSnap,
            x = _ref2.x,
            y = _ref2.y;

        var getCoords = function getCoords(X, Y) {
          var _this$convertXYtoView2 = _this.convertXYtoViewPort(X, Y),
              x = _this$convertXYtoView2.x,
              y = _this$convertXYtoView2.y;

          x = snapToGrip(x, gridSnap) - gridSnap / 2;
          y = snapToGrip(y, gridSnap);
          x -= _this.initialCursorOffset.x;
          y -= _this.initialCursorOffset.y;
          return {
            x: x,
            y: y
          };
        };

        if (this.draggedNode) {
          this.updateNode({
            id: this.draggedNode,
            name: 'coordinates',
            value: new Coordinates(getCoords(x, y))
          });
        }

        if (this.resizedNode) {
          var node = this.nodes[this.resizedNode];
          var coords = getCoords(x, y);
          var size = new Size({
            width: Math.abs(coords.x - node.coordinates.x),
            height: Math.abs(coords.y - node.coordinates.y)
          });

          if (size.width < 30) {
            size.width = 30;
          }

          if (size.height < 30) {
            size.height = 30;
          }

          this.updateNode({
            id: this.resizedNode,
            name: 'size',
            value: size
          });
        }
      },
      dragEnd: function dragEnd() {
        this.draggedNode = null;
        this.resizedNode = null;
      },
      updateNode: function updateNode(_ref3) {
        var id = _ref3.id,
            name = _ref3.name,
            value = _ref3.value;
        Vue.set(this.nodes[id], name, Node.prepareProp(name, value));
      }
    }
  };
});