var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    {
      on: {
        mouseenter: function($event) {
          _vm.hovered = true
        },
        mouseleave: function($event) {
          _vm.hovered = false
        },
        mousedown: function($event) {
          return _vm.$emit("mousedown", $event)
        }
      }
    },
    [
      _c("path", {
        style: _vm.largeStrokeStyle,
        attrs: { d: _vm.curve, "stroke-width": "8", fill: "none" }
      }),
      _c("path", {
        staticStyle: { stroke: "rgb(0,0,0)" },
        attrs: { d: _vm.curve, "stroke-width": "2", fill: "none" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }