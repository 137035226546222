function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }

function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); return Constructor; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { ulid } from 'ulid';
import Coordinates from './Coordinates';
import Size from './Size';

var Node = function () {
  function Node(attr) {
    _classCallCheck(this, Node);

    _defineProperty(this, "id", null);

    _defineProperty(this, "title", null);

    _defineProperty(this, "size", null);

    _defineProperty(this, "coordinates", null);

    _defineProperty(this, "data", {});

    _defineProperty(this, "portsIn", {});

    _defineProperty(this, "portsOut", {});

    this.id = Node.prepareProp('id', attr.id);
    this.title = attr.title;
    this.coordinates = Node.prepareProp('coordinates', attr.coordinates);
    this.size = Node.prepareProp('size', attr.size);
    this.data = Node.prepareProp('data', attr.data);
    this.portsIn = Node.prepareProp('portsIn', attr.portsIn);
    this.portsOut = Node.prepareProp('portsOut', attr.portsOut);
  }

  _createClass(Node, [{
    key: "portsHeight",
    get: function get() {
      return Math.max(Object.keys(this.portsIn).length, Object.keys(this.portsOut).length) * Node.PORT_HEIGHT;
    }
  }, {
    key: "width",
    get: function get() {
      return this.size.width;
    }
  }, {
    key: "height",
    get: function get() {
      return this.size.height;
    }
  }, {
    key: "x",
    get: function get() {
      return this.coordinates.x;
    }
  }, {
    key: "y",
    get: function get() {
      return this.coordinates.y;
    }
  }, {
    key: "x1",
    get: function get() {
      return this.coordinates.x + this.size.width;
    }
  }, {
    key: "y1",
    get: function get() {
      return this.coordinates.y + this.size.height;
    }
  }, {
    key: "getPortCoordinates",
    value: function getPortCoordinates(type, name) {
      if (!['in', 'out'].includes(type)) throw new Error('Undefined port type ' + type);
      var ports = Object.keys(type === 'in' ? this.portsIn : this.portsOut);
      var index = ports.findIndex(function (portName) {
        return portName === name;
      });

      if (index === -1) {
        throw new Error("\"".concat(type, "\" port with name \"").concat(name, "\" is undefined!"));
      }

      return {
        x: this.x + (type === 'out' ? this.width : 1),
        y: this.y + this.height - (this.portsHeight - 2) + index * Node.PORT_HEIGHT + 1
      };
    }
  }], [{
    key: "prepareProp",
    value: function prepareProp(name, value) {
      switch (name) {
        case 'id':
          return value || ulid();

        case 'coordinates':
          return new Coordinates(value || {});

        case 'size':
          return new Size(value || {});

        case 'portsIn':
        case 'portsOut':
          return _objectSpread({}, value || {});

        case 'data':
          return value || {};

        default:
          return value;
      }
    }
  }, {
    key: "intersect",
    value: function intersect(a, b) {
      return !(a.x > b.x1 || b.x > a.x1 || a.y > b.y1 || b.y > a.y1);
    }
  }]);

  return Node;
}();

_defineProperty(Node, "PORT_HEIGHT", 18);

export { Node as default };