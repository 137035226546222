function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { ulid } from "ulid";

var Link = function Link(props) {
  _classCallCheck(this, Link);

  _defineProperty(this, "id", null);

  _defineProperty(this, "start_id", null);

  _defineProperty(this, "start_port", null);

  _defineProperty(this, "end_id", null);

  _defineProperty(this, "end_port", null);

  this.id = props.id || ulid();
  if (!props.start_id) throw new Error('start_id is empty!');
  this.start_id = props.start_id;
  this.start_port = props.start_port || 'default';
  if (!props.end_id) throw new Error('end_id is empty!');
  this.end_id = props.end_id;
  this.end_port = props.end_port || 'default';
};

export { Link as default };